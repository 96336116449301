import * as React from "react"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import { H2, P } from "../styles/typography"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 60px;
  }
`
const Space = styled.div`
  padding-bottom: 46px;

  @media ${main.breakpoints.large} {
    padding-bottom: 150px;
  }
`

const GDPR = () => (
  <Layout>
    <Seo title="GDPR" />
    <HeadingWrapper>
      <H2>Hantering av personuppgifter</H2>
    </HeadingWrapper>
    <P>
      De insamlade uppgifterna kommer att sparas i interna datasystem och
      behandlas enligt EU:s förordning om personuppgiftsbehandling - GDPR.
      Uppgifterna sparas för att kunna följa upp de intresseanmälningar du som
      kund gör. Personuppgifterna hanteras bara av oss och våra
      underleverantörer.
    </P>
    <P>
      Genom att godkänna hanteringen av personuppgifter tillåter du också att vi
      gör mailutskick till angiven epostadress. Via{" "}
      <a href="mailto:info@4dgruppen.se">info@4dgruppen.se</a> kan du vända dig
      till oss och begära information om de personuppgifter som behandlas.
    </P>
    <Space />
  </Layout>
)

export default GDPR
